import "animate.css"
import contato from "static/images/contato.png"

import Footer from "components/Footer"
import Header from "components/Header"
import Layout from "components/Layout"
import Navbar from "components/Navbar"

import "./styles.scss"

const Contato = () => {
  return (
    <>
      <Navbar />
      <Header
        title="Contato"
        h1="Contate-nos"
        p="Suporte ágil 24/7, a qualquer momento que você precisar!"
        style={{ paddingBottom: "75px" }}
      />

      <Layout.White style={{ padding: "0" }}>
        <div className="container contato">
          <div className="row gtr-150" style={{ alignItems: "center" }}>
            <div className="col-6 col-12-medium imp-medium">
              <span className="image fit">
                <img
                  className="animate__animated animate__fadeInLeft"
                  src={contato}
                  alt="Arte para nos contatar"
                />
              </span>
            </div>
            <div className="col-6 col-12-medium">
              <div className="row gtr-150 contato__container">
                <a
                  href="https://www.instagram.com/flexus_solucoes/"
                  className="icon brands fa-instagram"
                  target="_BLANK"
                  rel="noreferrer"
                >
                  <span>@flexussolucoes</span>
                </a>
              </div>

              <div className="row gtr-150 contato__container">
                <a
                  href="https://www.linkedin.com/company/flexus-solucoes/"
                  className="icon brands fa-linkedin"
                  target="_BLANK"
                  rel="noreferrer"
                >
                  <span>/flexus-solucoes</span>
                </a>
              </div>

              <div className="row gtr-150 contato__container">
                <a
                  href="https://wa.me/551121499191?text=Olá%20Flexus,%20tenho%20interesse%20no%20seu%20serviço!"
                  className="icon brands fa-whatsapp"
                  target="_BLANK"
                  rel="noreferrer"
                >
                  <span>(11) 2149-9191</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Layout.White>

      <Footer />
    </>
  )
}

export default Contato

import { Autoplay, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/scss"
import "swiper/scss/autoplay"
import "swiper/scss/navigation"

import "./styles.scss"

const Slider = ({ slides, home = false }) => {
  return (
    <Swiper
      modules={[Navigation, Autoplay]}
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        750: {
          slidesPerView: 2,
        },
        950: {
          slidesPerView: 3,
        },
        1100: {
          slidesPerView: 4,
        },
      }}
      className="slider__swiper"
      loop={true}
      loopedSlides={true}
      loopPreventsSliding={false}
      navigation={true}
      autoplay={{ delay: 3000 }}
      autoplayDisableOnInteraction={false}
      direction="horizontal"
    >
      {slides?.map((slide) => {
        const { src, alt, title } = slide
        return (
          <SwiperSlide key={title} className={home ? "slider-home" : ""}>
            <img
              src={src}
              alt={alt}
              title={title}
              key={title}
              className={home ? "slider-home__img" : ""}
            />
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

export default Slider

import React from "react"
import { Link } from "react-router-dom"
import { HashLink as AnchorLink } from "react-router-hash-link"

import connected from "static/images/connected.png"
// import friends from "static/images/friends.png"
import teamwork from "static/images/team-work.png"

import Footer from "components/Footer"
import Header from "components/Header"
import Layout from "components/Layout"
import Navbar from "components/Navbar"

import useHome from "./hooks"
import "./styles.scss"

const Home = () => {
  const { posts, handleGetImage } = useHome()

  return (
    <>
      <Navbar />
      <Header title="Home" home />

      <Layout.White>
        <div className="container">
          <div className="row gtr-150">
            <div className="col-6 col-12-medium">
              <header className="major">
                <h2>Quem somos</h2>
              </header>
              <p>
                Nossa empresa é especializada em telecomunicações para empresas
                e corporações, com mais de 20 anos de experiência. Oferecemos
                soluções inovadoras e acessíveis em internet, telefonia,
                armazenamento de dados e firewall.{" "}
                <Link to="/quem-somos">Leia mais +</Link>
              </p>
            </div>
            <div className="col-6 col-12-medium imp-medium">
              <span className="image fit">
                <img
                  className="animate__animated animate__fadeInRight"
                  src={teamwork}
                  alt="Team work"
                />
              </span>
            </div>
          </div>
        </div>
      </Layout.White>

      <Layout.White>
        <div className="container">
          <div className="row gtr-150">
            <div className="col-6 col-12-medium imp-medium">
              <span className="image fit">
                <img className="home__image--internet" src={connected} alt="" />
              </span>
            </div>

            <div className="col-6 col-12-medium">
              <header className="major">
                <h2>Soluções para empresas</h2>
              </header>
              <p>
                A Flexus trabalha com soluções de telecomunicações para que a
                sua empresa seja sempre melhor assistida em recursos que vão de
                internet e telefonia a armazenamento de dados e tudo que envolve
                o mundo da TI. <Link to="/solucoes">Veja mais +</Link>
              </p>
            </div>
          </div>
        </div>
      </Layout.White>

      {/* <Layout.White >
        <div className="container">
          <div className="row gtr-150">
            <div className="col-6 col-12-medium">
              <header className="major">
                <h2>Nossos clientes</h2>
              </header>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Vestibulum gravida mauris a est consectetur, ut convallis nisl
                rutrum. Morbi quis nibh vitae ipsum volutpat scelerisque (25).{" "}
                <Link to="/clientes">Conheça mais +</Link>
              </p>
            </div>
            <div className="col-6 col-12-medium imp-medium">
              <span className="image fit">
                <img className="home__image--internet" src={friends} alt="" />
              </span>
            </div>
          </div>
        </div>
      </Layout.White> */}

      <Layout.White center>
        <div className="container">
          <header className="major">
            <h2>Últimas do Blog</h2>
          </header>

          <div className="row gtr-150">
            <div className="col-6 col-12-medium">
              <AnchorLink to={`/blog#${posts[0]?.title}`}>
                <div className="card">
                  <div
                    className="image-wrapper"
                    dangerouslySetInnerHTML={{
                      __html: handleGetImage(posts[0]?.content),
                    }}
                  />
                  <h3>{posts[0]?.title}</h3>
                </div>
              </AnchorLink>
            </div>
            <div className="col-6 col-12-medium">
              <AnchorLink to={`/blog#${posts[1]?.title}`}>
                <div className="card">
                  <div
                    className="image-wrapper"
                    dangerouslySetInnerHTML={{
                      __html: handleGetImage(posts[1]?.content),
                    }}
                  />
                  <h3>{posts[1]?.title}</h3>
                </div>
              </AnchorLink>
            </div>
          </div>
        </div>
      </Layout.White>

      <Footer />
    </>
  )
}

export default Home

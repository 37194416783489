import logoArklok from "static/logos/logo-arklok.png"
import logoBimachine from "static/logos/logo-bimachine.png"
import logoFortinet from "static/logos/logo-fortinet.png"
import logoAzure from "static/logos/logo-microsoftazure.png"
import logoOffice from "static/logos/logo-office-365.png"
import logoSql from "static/logos/logo-sqlserver.png"
import logoWindows from "static/logos/logo-windows10.png"

export const SLIDES = [
  {
    title: "Arklok",
    src: logoArklok,
    alt: "Logo do Arklok",
  },
  {
    title: "Bimachine",
    src: logoBimachine,
    alt: "Logo do Bimachine",
  },
  {
    title: "Microsoft Azure",
    src: logoAzure,
    alt: "Logo da Microsft Azure",
  },
  {
    title: "Fortinet",
    src: logoFortinet,
    alt: "Logo da Fortinet",
  },
  {
    title: "Office 365",
    src: logoOffice,
    alt: "Logo do Office 365",
  },
  {
    title: "SQL Server",
    src: logoSql,
    alt: "Logo do SQL Server",
  },
  {
    title: "Windows 10",
    src: logoWindows,
    alt: "Logo do Windows 10",
  },
]

export const MODAL_TITLE = {
  internet: "Acesso dedicado a internet",
  colocation: "Colocation",
  cloud: "",
  ramal: "Cloud PBXIP",
  telefonia: "Telefonia sip trunk",
  conexao: "Conexões ponto a ponto/ Nacional e internacional",
  firewall: "Firewall as service - FAS",
  fortinet: "Fortinet as service",
  fibra: "Projetos customizados de rede de fibra ótica",
}

export const MODAL_CONTENT = {
  internet: [
    "Um serviço de link dedicado é uma conexão de Internet de alta velocidade que fornece uma largura de banda dedicada e exclusiva para um único cliente. Diferente de conexões compartilhadas, como as de banda larga residencial ou empresarial, um link dedicado garante uma conexão estável e confiável, oferecendo uma largura de banda consistente e sem interrupções.",
    "A principal característica de um link dedicado é o fato de ser exclusivo para um único usuário ou empresa. Isso significa que a largura de banda contratada está disponível somente para o cliente, o que evita problemas de velocidade e desempenho causados pelo compartilhamento com outros usuários.",
    "Ao optar por um link dedicado, o cliente tem maior controle sobre a conexão, podendo personalizar a largura de banda de acordo com suas necessidades específicas. Isso é especialmente benéfico para empresas que exigem uma conexão confiável e de alta velocidade para executar aplicativos, serviços em nuvem, videoconferências, transferência de dados pesados ou qualquer atividade que demande uma conexão estável.",
    "Além disso, os provedores de link dedicado geralmente oferecem suporte técnico especializado e monitoramento contínuo da conexão, garantindo a máxima disponibilidade do serviço. Em caso de problemas, a equipe técnica está prontamente disponível para resolver as questões e minimizar o tempo de inatividade.",
    "Em resumo, um link dedicado é um serviço de Internet que fornece uma conexão de largura de banda exclusiva para um único cliente, garantindo estabilidade, velocidade e disponibilidade consistentes. É uma opção ideal para empresas que dependem de uma conexão de alta qualidade para executar suas operações de forma eficiente e confiável.",
  ],
  colocation: [
    "O serviço de colocation, também conhecido como data center colocation, é uma solução oferecida por provedores de serviços de data center, na qual empresas podem alugar espaço físico em um ambiente altamente seguro e com infraestrutura de alta tecnologia para hospedar seus servidores, equipamentos de rede e sistemas de armazenamento de dados.",
    "Basicamente, no colocation, as empresas têm a oportunidade de colocar seus próprios equipamentos em racks e gabinetes fornecidos pelo provedor de serviços de data center. Esses data centers são projetados para oferecer um ambiente controlado, com sistemas de energia redundantes, climatização adequada, sistemas de segurança física e monitoramento 24 horas por dia, 7 dias por semana.",
    "Em suma, o serviço de colocation oferece às empresas uma solução eficiente, segura e escalável para hospedar seus equipamentos de TI em um ambiente de data center profissional. Isso permite que elas se concentrem em suas principais atividades, enquanto desfrutam de uma infraestrutura confiável e de alto desempenho para suportar suas operações críticas.",
  ],
  cloud: [
    "Virtualização de sistemas",
    "A virtualização de sistemas é uma tecnologia que permite a criação de múltiplas instâncias virtuais de sistemas operacionais (SOs) e aplicativos em um único servidor físico. Essas instâncias virtuais, chamadas de máquinas virtuais (VMs), são isoladas entre si e compartilham os recursos do servidor subjacente, como processamento, memória e armazenamento.",
    "A virtualização de sistemas é amplamente adotada em ambientes de data centers, pois permite uma melhor utilização dos recursos de hardware, maior flexibilidade na implantação de aplicativos e uma infraestrutura mais fácil de gerenciar. Ela também serve como base para outras tecnologias, como nuvem privada e virtualização de desktops, impulsionando a eficiência e a agilidade dos ambientes de TI.",
    "Backup em nuvem",
    "Backup em nuvem é um serviço que permite fazer cópias de segurança de dados e armazená-las em servidores remotos na nuvem, por meio da internet. Em vez de depender de dispositivos de armazenamento físico, como discos rígidos externos ou fitas magnéticas, o backup em nuvem utiliza a infraestrutura e os recursos fornecidos por provedores de serviços em nuvem.",
    "Ao optar pelo backup em nuvem, os dados são criptografados e transferidos de forma segura para servidores remotos. Esses servidores estão localizados em data centers altamente seguros, com redundância de armazenamento e sistemas de backup em vigor. É importante selecionar um provedor de backup em nuvem confiável, que ofereça medidas de segurança adequadas, suporte técnico e políticas de privacidade claras. Além disso, é essencial considerar a largura de banda disponível para garantir que os backups possam ser realizados de forma eficiente, sem impactar a conectividade e o desempenho da rede.",
  ],
  ramal: [
    "O serviço de Cloud PBX (Private Branch Exchange) IP, ou PABX em Nuvem, é uma solução de telefonia baseada em nuvem que substitui a infraestrutura tradicional de telefonia no local. Ele permite que as empresas gerenciem suas comunicações telefônicas usando a internet, sem a necessidade de investir em equipamentos telefônicos físicos ou manutenção local.",
    "Com o Cloud PBX IP, as funcionalidades de um sistema telefônico tradicional são fornecidas por meio de servidores na nuvem o que permite extrema facilidade de crescimento, além de seus usuários terem seus ramais em qualquer lugar do planeta.",
  ],
  telefonia: [
    "A telefonia SIP Trunk é uma solução de comunicação que permite que as empresas façam chamadas de voz sobre IP por meio de uma conexão de rede. Ao contrário dos sistemas tradicionais de telefonia que utilizam linhas telefônicas físicas, a telefonia SIP Trunk utiliza a infraestrutura de rede existente para estabelecer chamadas telefônicas trazendo mais economia e escalabilidade. O sip trunk da Flexus permite planos de fale à vontade, isto é o usuário pode falar com todo o Brasil e o valor mensal será fixo.",
  ],
  conexao: [
    "Através de uma robusta rede fibra ótica podemos interligar diretamente, ponto A - ponto B, não importando a localidade, se houver viabilidade de rede, entregamos.",
  ],
  firewall: [
    "Entregamos e gerenciamos o melhor NGFW - Next Generation Firewall do mercado a nossos clientes - Fortinet.",
    "Não há necessidade de compra de equipamento, nós instalamos, gerenciamos e atualizamos. Mensalmente um relatório é entregue aos clientes a respeito de como anda a segurança da de sua rede e consumo de internet.",
  ],
  fortinet: [
    "Entregamos e gerenciamos o melhor NGFW - Next Generation Firewall do mercado a nossos clientes - Fortinet.",
    "Não há necessidade de compra de equipamento, nós instalamos, gerenciamos e atualizamos. Mensalmente um relatório é entregue aos clientes a respeito de como anda a segurança da de sua rede e consumo de internet.",
  ],
  fibra: [
    "Nosso time de engenharia desenha projetos de acordo com a necessidade dos clientes e uma vez aprovado a Flexus executa e entrega.",
  ],
}

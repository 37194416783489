import { useRouteError } from "react-router-dom"

import Footer from "components/Footer"
import Header from "components/Header"
import Layout from "components/Layout"

const ErrorPage = () => {
  const error = useRouteError()

  return (
    <>
      <Header
        title="Error"
        h1="Oops! Erro inesperado!"
        p={error?.statusText || error?.message}
      />
      <Layout.White style={{ padding: "0.5em" }}></Layout.White>
      <Footer />
    </>
  )
}

export default ErrorPage

import { useEffect, useState } from "react"

import axios from "axios"

const useHome = () => {
  const [posts, setPosts] = useState([])

  useEffect(() => {
    axios({
      method: "get",
      url: "https://www.googleapis.com/blogger/v3/blogs/3275948188112390082/posts?key=AIzaSyDTU5m4du-R-MLujqQSkfvcLEaSqU65Cpw",
    }).then((response) => {
      const currentPosts = response.data.items
      const initialPosts = currentPosts.slice(0, 2)
      setPosts(initialPosts)
    })
  }, [])

  const handleGetImage = (fullContent) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(fullContent, "text/html")
    const imgElement = doc.querySelector("img")

    if (imgElement) {
      return imgElement.outerHTML
    }

    return null
  }

  return { posts, handleGetImage }
}

export default useHome

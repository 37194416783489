/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { Link } from "react-router-dom"

import "animate.css"
import logo from "static/icons/logo.svg"

import "./styles.scss"

const Header = ({ h1, p, title = " ", home = false, ...props }) => {
  useEffect(() => {
    window.document.title = title + " | Flexus Soluções"
  }, [])

  return (
    <header id="header" className={!home ? "header__pages" : ""} {...props}>
      <div className="inner">
        {home ? (
          <>
            <img
              className="home__logo animate__animated animate__fadeInDown"
              src={logo}
              alt="Logo da Flexus"
              title="Flexus Soluções"
              width={100}
              height={100}
            />
            <h1 className="animate__animated animate__fadeInDown">
              Você chegou na
            </h1>
            <h1>
              <strong>Flexus Soluções</strong>
            </h1>
            <p className="animate__animated animate__fadeInDown"></p>
            <ul className="actions special">
              <li>
                <Link className="button scrolly" to="/quem-somos">
                  Conheça-nos
                </Link>
              </li>
            </ul>
          </>
        ) : (
          <>
            <h1 className="animate__animated animate__fadeInDown">{h1}</h1>
            <p className="animate__animated animate__fadeInDown">
              {p ? p : <></>}
            </p>
          </>
        )}
      </div>
    </header>
  )
}

export default Header

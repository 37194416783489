import "animate.css"
import greatsolutions from "static/images/great-solutions.png"

import Footer from "components/Footer"
import Header from "components/Header"
import Layout from "components/Layout"
import Navbar from "components/Navbar"
import Slider from "components/Slider"

import { SLIDES } from "./constants"
import useSolucoes from "./hooks"
import "./styles.scss"

const Solucoes = () => {
  const { showModal, handlerModal, modalContent } = useSolucoes()

  return (
    <>
      <Navbar />
      <Header title="Soluções" h1="Nossas soluções" />

      <Layout.White id="flexus" center>
        <div className="container">
          {/* <header className="major">
            <h2></h2>
          </header> */}

          <div className="row gtr-150">
            <div
              className="col-4 col-6-medium card"
              onClick={() => {
                handlerModal("internet")
              }}
            >
              <span className="image fit">
                <i className="icon solid fa-wifi home__image--icons"></i>
              </span>
              <p>
                Acesso dedicado à <br className="home__mobile" />
                internet
                <br className="home__mobile" />
                &nbsp;
                <br className="home__mobile" />
                &nbsp;
              </p>

              <div className="card__hover" />
            </div>
            <div
              className="col-4 col-6-medium card"
              onClick={() => {
                handlerModal("colocation")
              }}
            >
              <span className="image fit">
                <i className="icon solid fa-database home__image--icons"></i>
              </span>
              <p>
                Co-location
                <br />
                <br />
                &nbsp;
                <br />
                &nbsp;
              </p>

              <div className="card__hover" />
            </div>
            <div
              className="col-4 col-6-medium card"
              onClick={() => {
                handlerModal("cloud")
              }}
            >
              <span className="image fit">
                <i className="icon solid fa-cloud home__image--icons"></i>
              </span>
              <p>
                Cloud computing
                <br />
                Virtualização de sistemas
                <br />
                Backup em nuvem
                <br />
                &nbsp;
              </p>

              <div className="card__hover" />
            </div>
            <div
              className="col-4 col-6-medium card"
              onClick={() => {
                handlerModal("ramal")
              }}
            >
              <span className="image fit">
                <i className="icon solid fa-network-wired home__image--icons"></i>
              </span>
              <p>
                Cloud PBXIP
                <br />
                Seus ramais em qualquer
                <br />
                parte do mundo
                <br />
                &nbsp;
              </p>

              <div className="card__hover" />
            </div>
            <div
              className="col-4 col-6-medium card"
              onClick={() => {
                handlerModal("telefonia")
              }}
            >
              <span className="image fit">
                <i className="icon solid fa-phone home__image--icons"></i>
              </span>
              <p>
                Telefonia STFC
                <br />
                Conexão sip trunk
                <br />
                ilimitada para todo o país
                <br />
                &nbsp;
              </p>

              <div className="card__hover" />
            </div>
            <div
              className="col-4 col-6-medium card"
              onClick={() => {
                handlerModal("conexao")
              }}
            >
              <span className="image fit">
                <i className="icon solid fa-globe home__image--icons"></i>
              </span>
              <p>
                Conexões ponto a ponto
                <br />
                Nacional e internacional
                <br />
                &nbsp;
                <br />
                &nbsp;
              </p>

              <div className="card__hover" />
            </div>
            <div
              className="col-4 col-6-medium card"
              onClick={() => {
                handlerModal("firewall")
              }}
            >
              <span className="image fit">
                <i className="icon solid fa-lock home__image--icons"></i>
              </span>
              <p>
                Segurança de rede
                <br />
                Firewall gerenciado
                <br />
                &nbsp;
                <br />
                &nbsp;
              </p>

              <div className="card__hover" />
            </div>

            <div
              className="col-4 col-6-medium card"
              onClick={() => {
                handlerModal("fibra")
              }}
            >
              <span className="image fit">
                <i className="icon solid fa-ethernet home__image--icons"></i>
              </span>
              <p>
                Projetos customizados em
                <br />
                rede de fibra ótica
                <br />
                &nbsp;
                <br />
                &nbsp;
              </p>

              <div className="card__hover" />
            </div>
          </div>
        </div>

        <div className={`card__modal ${showModal ? "open" : "close"}`}>
          <p
            className="card__modal--button-close"
            onClick={() => handlerModal("")}
          >
            X
          </p>
          <header className="major">
            <h3>{modalContent.title}</h3>
          </header>
          {modalContent.content?.map((paragraph) => {
            return (
              <p
                style={
                  paragraph.length < 30
                    ? {
                        fontSize: "1.15em",
                        fontFamily: '"Carnas" !important',
                      }
                    : {}
                }
              >
                {paragraph}
              </p>
            )
          })}
        </div>
        <div
          className={`card__modal--overlay ${showModal ? "open" : "close"}`}
          onClick={() => {
            handlerModal("overlay")
          }}
        />
      </Layout.White>

      <Layout.Background id="parceiras">
        <div className="container">
          <div className="row gtr-150">
            <div className="col-12 col-12-medium">
              <header className="major">
                <h2>Soluções parceiras</h2>
              </header>

              <Slider slides={SLIDES} home />
            </div>
          </div>
        </div>
      </Layout.Background>

      <Layout.White>
        <div className="container">
          <div className="row gtr-150" style={{ alignItems: "center" }}>
            <div className="col-12 col-12-medium imp-medium">
              <span
                className="image fit"
                style={{ width: "50%", margin: "auto" }}
              >
                <img
                  className="animate__animated animate__fadeInLeft"
                  src={greatsolutions}
                  alt="Team workd"
                />
              </span>
            </div>
          </div>
        </div>
      </Layout.White>

      <Footer />
    </>
  )
}

export default Solucoes

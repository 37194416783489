const White = ({ center, children, ...props }) => {
  return (
    <section className={`main style1 ${center ? "special" : ""}`} {...props}>
      {children}
    </section>
  )
}

const Background = ({ children, ...props }) => {
  return (
    <section className="main style2 special" {...props}>
      {children}
    </section>
  )
}

// eslint-disable-next-line
export default {
  Background,
  White,
}

import { useEffect, useState } from "react"

import { MODAL_CONTENT, MODAL_TITLE } from "./constants"

const useSolucoes = () => {
  const [showModal, setShowModal] = useState(false)
  const [modalSolution, setModalSolution] = useState("")
  const [modalContent, setModalContent] = useState({
    title: "",
    content: [],
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    // Blocking scroll outside modal

    if (showModal) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
    }
  }, [showModal])

  useEffect(() => {
    setModalContent({
      title: MODAL_TITLE[modalSolution],
      content: MODAL_CONTENT[modalSolution],
    })
  }, [modalSolution])

  const handlerModal = (content) => {
    setModalSolution(content)
    setShowModal((state) => !state)
  }

  return { showModal, handlerModal, modalContent }
}

export default useSolucoes

const Footer = () => {
  return (
    <section id="footer">
      <ul className="icons">
        <li>
          <a
            href="https://www.instagram.com/flexus_solucoes/"
            className="icon brands fa-instagram"
            target="_BLANK"
            rel="noreferrer"
          >
            <span className="label">Instagram</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/company/flexus-solucoes/"
            className="icon brands fa-linkedin"
            target="_BLANK"
            rel="noreferrer"
          >
            <span className="label">LinkedIn</span>
          </a>
        </li>

        <li>
          <a
            href="https://wa.me/551121499191?text=Olá%20Flexus,%20tenho%20interesse%20no%20seu%20serviço!"
            className="icon brands fa-whatsapp"
            target="_BLANK"
            rel="noreferrer"
          >
            <span className="label">WhatsApp</span>
          </a>
        </li>
      </ul>
      <ul className="copyright">
        <li>&copy; Flexus Soluções</li>
      </ul>
    </section>
  )
}

export default Footer

export const CLIENT_TESTIMONIES = [
  {
    name: "Wilson Costacurta",
    company: "Totvs Ibirapuera",
    text: "Eu conheço a Flexus há mais ou menos 13 anos. Estávamos em um outro escritório e buscávamos uma empresa sólida pra ser nossa fornecedora tanto de telefonia, quanto prestadora de serviços para internet. A Flexus ajudou muito com a mobilidade e a agilidade na telefonia. Na pandemia precisamos deslocar 160 colaboradores para home office, e  fizemos com que cada um tivesse um ramal na sua casa. A Flexus entrega o que contratamos. Nós da TOTVS estamos felizes com o serviço.",
  },
  {
    name: "Jorge Luiz Gomes da Cruz",
    company: "Gangster - Moda Masculina",
    text: "Quando cheguei na Gangster, a Flexus já estava prestando um serviço aqui. Como não havia nenhuma reclamação, dei continuidade no trabalho. Logo em sequência, fizemos uma parceria: colocamos anéis de fibra óptica interligando três das nossas unidades. Foi um trabalho muito bacana, não tivemos problema nenhum. Demos continuidade, fizemos telefonia junto. Montamos uma unidade em Guarulhos interligada ao Brás e foi bem difícil conseguir uma empresa que atendesse nosso prazo e a conexão LAN to LAN. A Flexus se comprometeu e fez. Uma empresa parceira, uma empresa amiga. Todos os projetos que fizemos eles estavam conosco. Acabamos de realizar uma nova implantação de telefonia, eles nos deram todo suporte. Pretendemos manter a parceria por muito tempo.",
  },
  {
    name: "José Adilson Rossi Junior",
    company: "Seiki Atacado e Varejo",
    text: "Conhecemos a Flexus há mais de 10 anos, pela internet. Buscava soluções para resolver as constantes quedas e problemas em nosso link de internet. A contratação e resolução desse problema com o link dedicado foi feita muito rapidamente, não tivemos mais problemas quanto a isso. Gostamos da Flexus por conta de um atendimento rápido e mais humanizado, conseguimos um retorno rápido e satisfatório.",
  },
  {
    name: "Marcus Militão",
    company: "Pibernat Logística",
    text: "Raramente ocorre indisponibilidade no acesso, muito robusto, ágil e eficaz. Serviço sem comparação prestado pela nossa fornecedora de software.",
  },
  {
    name: "Anderson Carlos Fernandes Lopes",
    company: "Clovis Calçados",
    text: "Eu conheço e venho usando o serviços da Flexus por aproximadamente seis anos. Aqui na empresa entramos em um processo de reestruturação de toda rede, tanto LAN quanto WAN. A Flexus fez parte disto, com a qualidade e a entrega dos links dentro do que precisávamos. A equipe tem um atendimento amigável, é tranquilo abrir um chamado, atende o mais rápido possível, perfeitamente. Temos uma parceria muito boa. Estou muito satisfeito.",
  },
]

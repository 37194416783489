import { Link } from "react-router-dom"

import Footer from "components/Footer"
import Header from "components/Header"
import Layout from "components/Layout"
import Navbar from "components/Navbar"

import useBlog from "./hooks"
import "./styles.scss"

const Blog = () => {
  const {
    posts,
    handlerPostPreview,
    showModal,
    handleModal,
    handleGetImage,
    handleModalPost,
    postToModal,
  } = useBlog()

  return (
    <>
      <Navbar />
      <Header title="Blog" h1="Blog" p="Fique por dentro das novidades" />

      {posts.length ? (
        posts.map((post, index) => {
          const isOdd = index % 2 !== 0

          if (index > 8) return null

          if (isOdd) {
            return (
              <Layout.White id={post.title} key={index}>
                <div className="container">
                  <div className="row gtr-150">
                    <div className="col-6 col-12-medium imp-medium">
                      <span
                        className="image fit"
                        dangerouslySetInnerHTML={{
                          __html: handleGetImage(post.content),
                        }}
                      />
                    </div>

                    <div className="col-6 col-12-medium">
                      <header className="major">
                        <h3>{post.title}</h3>
                      </header>
                      <p>
                        {handlerPostPreview(post.content)}
                        {"... "}
                        <Link
                          onClick={() => {
                            handleModal()
                            handleModalPost(post)
                          }}
                        >
                          Leia mais
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </Layout.White>
            )
          } else {
            return (
              <Layout.White id={post.title} key={index}>
                <div className="container">
                  <div className="row gtr-150">
                    <div className="col-6 col-12-medium">
                      <header className="major">
                        <h3>{post.title}</h3>
                      </header>
                      <p>
                        {handlerPostPreview(post.content)}
                        {"... "}
                        <Link
                          onClick={() => {
                            handleModal()
                            handleModalPost(post)
                          }}
                        >
                          Leia mais
                        </Link>
                      </p>
                    </div>

                    <div className="col-6 col-12-medium imp-medium">
                      <span
                        className="image fit"
                        dangerouslySetInnerHTML={{
                          __html: handleGetImage(post.content),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Layout.White>
            )
          }
        })
      ) : (
        <div className="loader-wrapper">
          <div className="blog-loader" />
        </div>
      )}

      <div className={`blog-modal ${showModal ? "open" : "close"}`}>
        <div className="blog-modal__content">
          <p className="blog-modal__button-close" onClick={handleModal}>
            X
          </p>
          <header className="major">
            <h2>{postToModal.title}</h2>
          </header>
          <div
            dangerouslySetInnerHTML={{
              __html: postToModal.content,
            }}
          />
        </div>
      </div>

      <div
        className={`blog-overlay ${showModal ? "open" : "close"}`}
        onClick={handleModal}
      ></div>

      <Footer />
    </>
  )
}

export default Blog

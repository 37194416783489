import { useEffect } from "react"

import "animate.css"

import Footer from "components/Footer"
import Header from "components/Header"
import Layout from "components/Layout"
import Navbar from "components/Navbar"

const Clientes = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Navbar />
      <Header title="Clientes" h1="Nossos clientes" />

      <Layout.White>
        <div className="container">
          <header className="major">
            <h2>Lorem ipsum dolor (3)</h2>
          </header>
          <div className="row gtr-150">
            <div className="col-3 col-6-medium animate__animated animate__fadeInRight">
              <span className="image fit">
                <img
                  src="https://via.placeholder.com/200x100"
                  alt="Segurança"
                />
              </span>
            </div>
            <div className="col-3 col-6-medium animate__animated animate__fadeInRight">
              <span className="image fit">
                <img
                  src="https://via.placeholder.com/200x100"
                  alt="Segurança"
                />
              </span>
            </div>
            <div className="col-3 col-6-medium animate__animated animate__fadeInRight">
              <span className="image fit">
                <img
                  src="https://via.placeholder.com/200x100"
                  alt="Segurança"
                />
              </span>
            </div>
            <div className="col-3 col-6-medium animate__animated animate__fadeInRight">
              <span className="image fit">
                <img
                  src="https://via.placeholder.com/200x100"
                  alt="Segurança"
                />
              </span>
            </div>
            <div className="col-3 col-6-medium animate__animated animate__fadeInRight">
              <span className="image fit">
                <img
                  src="https://via.placeholder.com/200x100"
                  alt="Segurança"
                />
              </span>
            </div>
            <div className="col-3 col-6-medium animate__animated animate__fadeInRight">
              <span className="image fit">
                <img
                  src="https://via.placeholder.com/200x100"
                  alt="Segurança"
                />
              </span>
            </div>
            <div className="col-3 col-6-medium animate__animated animate__fadeInRight">
              <span className="image fit">
                <img
                  src="https://via.placeholder.com/200x100"
                  alt="Segurança"
                />
              </span>
            </div>
            <div className="col-3 col-6-medium animate__animated animate__fadeInRight">
              <span className="image fit">
                <img
                  src="https://via.placeholder.com/200x100"
                  alt="Segurança"
                />
              </span>
            </div>
            <div className="col-3 col-6-medium animate__animated animate__fadeInRight">
              <span className="image fit">
                <img
                  src="https://via.placeholder.com/200x100"
                  alt="Segurança"
                />
              </span>
            </div>
            <div className="col-3 col-6-medium animate__animated animate__fadeInRight">
              <span className="image fit">
                <img
                  src="https://via.placeholder.com/200x100"
                  alt="Segurança"
                />
              </span>
            </div>
            <div className="col-3 col-6-medium animate__animated animate__fadeInRight">
              <span className="image fit">
                <img
                  src="https://via.placeholder.com/200x100"
                  alt="Segurança"
                />
              </span>
            </div>
            <div className="col-3 col-6-medium animate__animated animate__fadeInRight">
              <span className="image fit">
                <img
                  src="https://via.placeholder.com/200x100"
                  alt="Segurança"
                />
              </span>
            </div>
          </div>
        </div>
      </Layout.White>

      <Footer />
    </>
  )
}

export default Clientes

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { HashLink as AnchorLink } from "react-router-hash-link"

import "./styles.scss"

const Navbar = () => {
  const [isActive, setIsActive] = useState(false)
  const [activeSubitem, setActiveSubitem] = useState(false)
  const [activeSubitemSolucoes, setActiveSubitemSolucoes] = useState(false)
  const [isTop, setIsTop] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset

      if (scrollTop > 0 && isTop) {
        setIsTop(false)
      } else if (scrollTop === 0 && !isTop) {
        setIsTop(true)
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [isTop])

  const toggleMenu = () => {
    setIsActive((state) => !state)
    window.scrollTo(0, 0)
  }

  return (
    <nav
      className={`navbar ${isActive ? "is-active" : ""} ${
        !isTop ? "not-top" : ""
      }`}
    >
      <div className={`circle ${isActive ? "expand" : ""}`}></div>

      <div className="navbar-brand">
        <div
          className={`navbar-burger ${isActive ? "is-active" : ""} ${
            isActive ? "open" : ""
          }`}
          onClick={toggleMenu}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className="navbar-menu">
        <ul className="navbar-nav">
          <li className="navbar-item">
            <Link to="/" onClick={toggleMenu}>
              Home
            </Link>
          </li>
          <li
            className="navbar-item navbar-subitem"
            onMouseOver={() => setActiveSubitem(true)}
            onMouseLeave={() => setActiveSubitem(false)}
          >
            <a onClick={() => setActiveSubitem((state) => !state)}>
              Quem Somos
              <i className="icon solid fa-chevron-down"></i>
            </a>
            <ul
              className={`navbar-subitem__dropdown ${
                activeSubitem ? "navbar-subitem__active" : ""
              } ${
                !isTop ? "navbar-subitem__background" : "navbar-subitem__shadow"
              }`}
            >
              <li>
                <AnchorLink to="/quem-somos#historia" onClick={toggleMenu}>
                  História
                </AnchorLink>
              </li>
              <li>
                <AnchorLink to="/quem-somos#estrutura" onClick={toggleMenu}>
                  Estrutura
                </AnchorLink>
              </li>
              <li>
                <AnchorLink to="/quem-somos#missao" onClick={toggleMenu}>
                  Missão
                </AnchorLink>
              </li>
            </ul>
          </li>
          <li
            className="navbar-item navbar-subitem"
            onMouseOver={() => setActiveSubitemSolucoes(true)}
            onMouseLeave={() => setActiveSubitemSolucoes(false)}
          >
            <a onClick={() => setActiveSubitemSolucoes((state) => !state)}>
              Soluções
              <i className="icon solid fa-chevron-down"></i>
            </a>
            <ul
              className={`navbar-subitem__dropdown ${
                activeSubitemSolucoes ? "navbar-subitem__active-solucoes" : ""
              } ${
                !isTop ? "navbar-subitem__background" : "navbar-subitem__shadow"
              }`}
            >
              <li>
                <AnchorLink to="/solucoes" onClick={toggleMenu}>
                  Flexus
                </AnchorLink>
              </li>
              <li>
                <AnchorLink to="/solucoes#parceiras" onClick={toggleMenu}>
                  Parceiras
                </AnchorLink>
              </li>
            </ul>
          </li>
          <li className="navbar-item">
            <Link to="/depoimentos" onClick={toggleMenu}>
              Depoimentos
            </Link>
          </li>
          <li className="navbar-item">
            <Link to="/contato" onClick={toggleMenu}>
              Contato
            </Link>
          </li>
          <li className="navbar-item">
            <Link to="/blog" onClick={toggleMenu}>
              Blog
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Navbar

import { useEffect } from "react"

import "animate.css"
import missao from "static/images/missao.png"
import estrutura from "static/images/nossa-estrutura.png"
import teamwork from "static/images/team-work.png"

import Footer from "components/Footer"
import Header from "components/Header"
import Layout from "components/Layout"
import Navbar from "components/Navbar"

import "./styles.scss"

const QuemSomos = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Navbar />
      <Header title="Quem Somos" h1="Quem somos" />

      <Layout.White id="historia">
        <div className="container">
          <div className="row gtr-150">
            <div className="col-6 col-12-medium">
              <p>
                Somos uma empresa especializada em telecomunicações para
                empresas e corporações, que atua há mais de 20 anos buscando
                soluções efetivas, inovadoras, práticas e com custo-benefício
                acessível de internet, telefonia, armazenamento de dados,
                firewall.
                <br />
                <br />
                A Flexus surgiu em São Paulo, mais precisamente no Brás, como
                uma empresa provedora de internet apenas. Uma década depois,
                ramificamos o campo de atuação para outras áreas de TI,
                alcançando mais de 1500 clientes em todo Brasil.
                <br />
                <br />
                Hoje, seguimos evoluindo e aprimorando nossos serviços com
                recursos de última geração, além do atendimento diferenciado que
                proporcionamos aos nossos clientes, como suporte 24 horas por
                dia, nos 7 dias da semana.
              </p>
            </div>
            <div className="col-6 col-12-medium imp-medium">
              <span className="image fit">
                <img
                  className="animate__animated animate__fadeInRight"
                  src={teamwork}
                  alt="Team work"
                />
              </span>
            </div>
          </div>
        </div>
      </Layout.White>

      <Layout.White id="estrutura" center>
        <div className="container">
          <div className="row gtr-150">
            <div className="col-12 col-12-medium">
              <header className="major">
                <h2>Estrutura de atendimento</h2>
              </header>

              <span>
                <img src={estrutura} alt="Nossa estrutura" />
              </span>
            </div>
          </div>
        </div>
      </Layout.White>

      <Layout.White id="missao">
        <header className="major" style={{ textAlign: "center" }}>
          <h2>Nossa missão, visão e valores</h2>
        </header>
        <div className="container">
          <div className="row gtr-150" style={{ alignItems: "center" }}>
            <div className="col-6 col-12-medium imp-medium">
              <span className="image fit">
                <img
                  className="animate__animated animate__fadeInLeft"
                  src={missao}
                  alt="Nossa missão, visão e valores"
                />
              </span>
            </div>
            <div className="col-6 col-12-medium">
              <p>
                <strong>Missão:</strong>
                <br />
                Simplificar processos de telecomunicações em empresas, levando
                soluções inovadoras, eficientes, com suporte ágil e bom
                custo-benefício para o cliente.
                <br /> <br />
                <strong>Visão:</strong>
                <br /> Ser reconhecida como uma empresa de soluções em
                telecomunicações diferenciada no mercado pela qualidade da
                entrega, transparência e foco no cliente.
                <br /> <br />
                <strong>Valores:</strong>
                <br />
                <li>Somos éticos e transparentes</li>
                <li>Somos comprometidos com a promessa ao cliente</li>
                <li>Somos inovadores</li>
                <li>Trabalhamos em equipe</li>
                <li>Buscamos melhora e aperfeiçoamento todos os dias</li>
              </p>
            </div>
          </div>
        </div>
      </Layout.White>

      <Footer />
    </>
  )
}

export default QuemSomos

import "./_breakpoints.min"
import "./_browser.min"
import "./_jquery.min"
import "./_jquery.scrolly.min"
import "./_main"
import "./_util"
import "./_web"

const docs = () => {}
export default docs

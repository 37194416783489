import Footer from "components/Footer"
import Header from "components/Header"
import Layout from "components/Layout"
import Navbar from "components/Navbar"

import { CLIENT_TESTIMONIES } from "./constants"
import "./styles.scss"

const Depoimentos = () => {
  return (
    <>
      <Navbar />
      <Header title="Depoimentos" h1="O que dizem nossos clientes" />

      {CLIENT_TESTIMONIES.map((testimony, index) => {
        const isOdd = index % 2

        return (
          <div className="depoimentos" key={index}>
            <Layout.White id={testimony.name} style={{ padding: "3em 0" }}>
              <div className="container">
                <div className="row gtr-150">
                  <div className="col-12 col-12-medium depoimentos__text">
                    <i
                      className="icon solid fa-quote-right"
                      style={
                        !isOdd ? { display: "block", textAlign: "right" } : {}
                      }
                    ></i>
                    <p
                      style={
                        !isOdd
                          ? { textAlign: "right", fontStyle: "italic" }
                          : { fontStyle: "italic" }
                      }
                    >
                      {testimony.text}
                    </p>

                    <p style={!isOdd ? { float: "right" } : {}}>
                      <strong>{testimony.name}</strong>, {testimony.company}
                    </p>
                  </div>
                </div>
              </div>
            </Layout.White>

            <hr className="depoimentos__line" />
          </div>
        )
      })}

      <Footer />
    </>
  )
}

export default Depoimentos

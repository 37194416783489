import { useEffect, useState } from "react"

import axios from "axios"

const useBlog = () => {
  const [posts, setPosts] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [postToModal, setPostToModal] = useState({
    title: "",
    content: "",
  })

  useEffect(() => {
    axios({
      method: "get",
      url: "https://www.googleapis.com/blogger/v3/blogs/3275948188112390082/posts?key=AIzaSyDTU5m4du-R-MLujqQSkfvcLEaSqU65Cpw",
    }).then((response) => {
      setPosts(response.data.items)
    })
  }, [])

  useEffect(() => {
    // Blocking scroll outside modal

    if (showModal) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
    }
  }, [showModal])

  const handlerPostPreview = (fullContent) => {
    fullContent = fullContent.replace(/<h1\b[^>]*>(.*?)<\/h1>/gi, "")
    fullContent = fullContent.replace(/<[^>]+>/g, "")
    fullContent = fullContent.replace(/&nbsp;/g, "")

    fullContent = fullContent.replace(
      /([.?!])([^ ])/g,
      (match, group1, group2) => {
        return `${group1} ${group2}`
      }
    )

    const words = fullContent.trim().split(/\s+/)
    const limitedWords = words.slice(0, 30)

    return limitedWords.join(" ")
  }

  const handlerRemoveH1 = (fullContent) => {
    return (fullContent = fullContent.replace(/<h1\b[^>]*>(.*?)<\/h1>/gi, ""))
  }

  const handleGetP = (fullContent) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(fullContent, "text/html")
    const paragraphs = doc.getElementsByTagName("p")

    // Remover estilos dos parágrafos
    for (let i = 0; i < paragraphs.length; i++) {
      paragraphs[i].removeAttribute("style")
    }

    const allTags = doc.getElementsByTagName("*")
    // Remover estilos de todas as tags
    for (let i = 0; i < allTags.length; i++) {
      allTags[i].removeAttribute("style")
      allTags[i].removeAttribute("color")
      allTags[i].removeAttribute("face")
      allTags[i].removeAttribute("class")
    }

    const result = []

    // Remover tags vazias e adicionar parágrafos não vazios à lista de resultados
    for (let i = 0; i < paragraphs.length; i++) {
      if (paragraphs[i].innerHTML.trim() !== "") {
        result.push(paragraphs[i].outerHTML)
      } else {
        paragraphs[i].remove()
      }
    }

    const allParagraphs = result.join(" ")

    // Remover todas as ocorrências de "&nbsp;"
    const finalContent = allParagraphs.replace(/&nbsp;/g, "")

    return finalContent
  }

  const handleGetImage = (fullContent) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(fullContent, "text/html")
    const imgElement = doc.querySelector("img")

    if (imgElement) {
      return imgElement.outerHTML
    }

    return null
  }

  const handleModal = () => {
    setShowModal((state) => !state)
  }

  const handleModalPost = (post) => {
    const title = post.title
    const content = handleGetP(post.content)

    setPostToModal({ title, content })
  }

  return {
    posts,
    handlerPostPreview,
    handlerRemoveH1,
    showModal,
    handleModal,
    handleGetImage,
    handleModalPost,
    postToModal,
  }
}

export default useBlog

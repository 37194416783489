import { createHashRouter } from "react-router-dom"

import Blog from "pages/Blog"
import Clientes from "pages/Clientes"
import Contato from "pages/Contato"
import Depoimentos from "pages/Depoimentos"
import ErrorPage from "pages/ErrorPage"
import Home from "pages/Home"
import QuemSomos from "pages/QuemSomos"
import Solucoes from "pages/Solucoes"

export const router = createHashRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/quem-somos",
    element: <QuemSomos />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/solucoes",
    element: <Solucoes />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/depoimentos",
    element: <Depoimentos />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/clientes",
    element: <Clientes />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/contato",
    element: <Contato />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/blog",
    element: <Blog />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/404",
    element: <ErrorPage />,
    errorElement: <ErrorPage />,
  },
])
